//Dropdown menu
var burgerBtn = document.querySelector('.o-burger');
var nav = document.querySelector('.c-nav');
var b = document.querySelector('body');

burgerBtn.addEventListener('click', function() {
	this.classList.toggle('active');
	if (this.classList.contains('active')) {
		nav.style.width = 100 + '%';
		b.style.overflow="hidden";
	}
	else {
		nav.style.width = 0 + 'px';
		b.style.overflow="scroll";
	}
})

//Collapse
var acc = document.querySelectorAll(".c-collapse__btn");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
   
    this.classList.toggle("active");

    var panel = this.nextElementSibling;
    if (panel.style.height === panel.scrollHeight + 'px') {
      panel.style.height = '0px';
    } else {
      panel.style.height = panel.scrollHeight + 'px';
    }
  });
}

//Iframe preload
var videoBlock = document.querySelectorAll('.c-video-wrap__preview');

videoBlock.forEach(function (element) {
	var id = element.getAttribute('data-id');

	element.children[0].addEventListener('click', function (event) {
		var parentBlock = this.parentNode;
		var frame = parentBlock.nextElementSibling;

		frame.setAttribute('src', 'https://www.youtube.com/embed/' + id);
		
		parentBlock.style.display = 'none';
		frame.style.display = 'block';
	})
});